.calendar {
	.compact--hide {
		max-height: 200vh;
		opacity: 1;
		@include transition(all,.5s);
        overflow: hidden;
	}
	&--compact-mode {
		.compact--hide {
			max-height: 0px;
			opacity: 0;
		}
		.calendar__header-title {
			&:before {
				transform: rotate(45deg);
			}
		}
	}
	& * {
		user-select: none;
	}
	&__header {
	}
	&__prev, &__next {
		cursor: pointer;
		opacity: 0.7;
		display: flex;
		align-items: center;
		justify-content: center;
		width : 30px;
		@include transition(background-color);
		&:before {
			content: '';
			display: inline-block;
			width: 10px;
			height: 10px;
			border : solid $color-black;
			border-width: 1px 1px 0 0;
			transform: rotate(45deg);
		}
	}
	&__next {
		margin-left: auto;
	}
	&__prev {
		transform: rotate(180deg);
		margin-right: auto;
	}
	&__header {
	}
	&__header-title {
		text-align: center;
		padding: 5px 0;
		cursor: pointer;
		position: relative;
		font-size: $fontsize-h4;
		&:before {
			display : none;
			content:'';
			position: absolute;
			bottom: 5px;
			left: calc(50% - 5px);
			transform: translateY(6px) rotate(225deg);
			width: 8px;
			height : 8px;
			border : solid $color-grey-darkest;
			border-width: 0 1px 1px 0;
			@include transition(transform);
		}
	}

	&__reset {
		width: 40px;
		color: $color-primary;
		cursor: pointer;
		text-align: center;
		padding: 15px 0;
		display: none;
	}
	.date {
		&__name {
			//border-bottom: 1px solid $color-grey;
		}
		&__row {
			display: flex;
			flex-flow: row wrap;
			&:nth-child(3) {
				.date__item {
					border-top-width: 1px;
				}
			}
		}
		&__column {
			width: calc(100% / 7);
			overflow: hidden;
			text-align: center;
		}
		&__item {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 30px;
			cursor: pointer;
			@include transition(background-color);
			&.sat {
				color:$color-info;
			}
			&.sun {
				color:$color-error;
			}
			&.faded {
				opacity: 0.6;
				display: none;
			}
			&--today {
			    border-bottom: 1px solid $color-error!important;
			}
			&--selected {
				background-color: $color-info;
				color: $color-white!important;
			}
			&--disabled {
				pointer-events: none;
				color: $color-grey-darkest!important;
				background-color: $color-grey-lightest;
			}
		}
		&__header {
			pointer-events: none;
			font-weight: 600;
		}
		
  	}
}

.has-border {
	border: solid $color-grey-light;
	border-width: 0 1px 1px 0;
	.date__item {
		border: solid $color-grey-light;
		border-width: 0 0 0 1px;
	}
	&:first-of-type {
		.date__item {
			border-width: 1px 0 0 1px;
		}
	}
	&:last-of-type {
		border-width: 0 0 0 1px;
		.date__item {
			border-width: 0 1px 1px 0;
		}
	}
}

.swipe {
	@include transition(transform);
}