/* Breakpoint */
@mixin mobile {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin mobile-small {
    @media (max-width: #{$mobile-width}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}
@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}
@mixin mobile-tablet {
    @media (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}
@mixin tablet-desktop {
    @media (min-width: #{$tablet-width}) {
        @content;
    }
}
@mixin transition($attr : all, $time : 0.3s, $type: ease) {
    -moz-transition: #{$attr} #{$time} #{$type};
    -o-transition: #{$attr} #{$time} #{$type};
    -webkit-transition: #{$attr} #{$time} #{$type};
    transition: #{$attr} #{$time} #{$type};
}

@mixin gradient-red {
    background-image: linear-gradient(to right, #eb3349, #f45c43);
    color: $color-white;
    height: 40px;
}
@mixin gradient-blue {
    background-image: linear-gradient(to right, #4B57EB, #00C8FE);
    color: $color-white;
}
@mixin gradient-green {
    background: rgba(173,216,230,.9);
    color: #000;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}
@mixin gradient-grey {
    background-image: linear-gradient(to right, #2c3e50, #bdc3c7);
    color: $color-white;
}
@mixin gradient-pink {
    background-image: linear-gradient(to right, #ffafbd, #ffc3a0 );
    color: $color-white;
}
