.popup {
	/*position: absolute;
	width: calc(100vw - 30px);
	top: 50%;
	left: calc(15px);
	transform: translate(0,-50%);*/
	width: 75%;
	max-width: 400px;
	min-width: 300px;
	overflow-x: hidden;
	margin: auto!important;
	p {
		margin: 0 0 10px 0;
	}

	.button--success, .button--danger, .button--info{
		height: 30px !important;
		font-size: 14px !important;
		text-transform: capitalize;
	}

	&__overlay {
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(0,0,0,0.5);
		z-index: 999;
		transition : all 0.3s ease;
		transform-origin: top;
		padding-top: 20px;
		&.popup--hide {
			opacity: 0;
			pointer-events: none;
		}
	}
	&__header {
		background-color: $color-grey-lightest;
		border-bottom: 1px dashed $color-grey-light;
		display: flex;
		align-items: center;
		padding: 10px 5px;
	}
	&__title {
		flex: 1 1 auto;
		padding-left: 24px;
		text-align: center;
		font-weight: bold;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 1.5em;
		font-size: 16px;
	}
	&__closer {
		flex: 0 0 24px;
		height: 24px;
		@include icon(multiply, $color-black); 
		background-size: 16px;
		cursor: pointer;
	}
	&__content {
		background-color: #fff;
		padding: 10px 15px;
		word-break: break-word;
		max-height: calc(100vh - 40px - 34px);
		overflow: auto;
	}
}