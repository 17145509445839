body {
	margin: 0;
	font-size: $fontsize-normal;
    color: $color-black-solid;
    background: grey;
    //min-height: 900px;
    //padding: 100px;
}
#app {
  max-width: 600px;
  margin: 20px auto;
}
body *{
  font-family: $font-family;
  line-height: 1.2;
  box-sizing: border-box;
  font-weight: 400;
}

.pac-container:after {
  content:none !important;
}

.container {
  width: 100%;
  margin: auto;
}

@media (min-width: 768px) {
  .container {
    max-width:768px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width:970px
  }
}

@media (min-width: 1200px) {
  .container {
    max-width:1170px
  }
}

.pull-right {
  margin-left: auto;
}
