.tab {
    &__header-container {
        //border-bottom : 1px solid $color-grey;
    }
    &__header {
        display : inline-block;
        padding : 10px 20px;
        cursor: pointer;
        margin-bottom : -1px;
        border : none;
        background-color : $color-none;
        &:first-of-type {
            border-left-width: 1px;
        }
        &.active {
            background-color : $color-none;
            cursor: default;
        }
    }
    &__panel {
        display : none;
        //border: 1px solid $color-grey;
        border-top: none;
        background-color : $color-none;
        //padding : 20px;
        &.active {
            display : block;
        }
        /*@include mobile {
            padding: 20px 5px;
        }*/
    }
}
