.tip {
    &__container {
        margin-bottom: 15px;
        padding-left: 10px;
    }

    &__slogan {
        color: $color-white !important;
        font-weight: 600;
        font-size: 14px;
    }

    &__selector {
        cursor: pointer;
        font-size: 12px;
        font-weight: bold;
        padding: 5px 7.5px;
        display: inline-block;
        border: 1px solid $color-grey;
        margin: 5px 7.5px 0 0;
        border-radius: 4px;
        background: $color-white;
        color: $color-black-solid;
        &--active {
            background-color: $color-button;
            color: $color-black-solid;
        }
        &--reset {
            width: 25px;
            cursor: pointer;
            height: 25px;
            position: relative;
            background-color: $color-button;
            &:before {
                @include icon(multiply, $color-black-solid);
                background-size: 20px;
                content:'';
                width: 24px;
                height: 24px;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}
