.step {
    &__header-container {
        height: 60px;
        background-color: $color-grey-lighter;
        border: 1px dashed $color-grey;
        display: flex;
        padding: 0 10px;
        display: none;
        @include mobile {
            padding: 0;
        }
    }
    &__header {
        width: 60px;
        display: inline-flex;
        background-size: 24px;
        align-items : center;
        justify-content : center;
    }
    &__header-delimiter {
        flex : 1 1 auto;
        height: 30px;
        &.active {
            border-bottom-color: $color-success;
        }
    }
    &__panels-container { 
    }
    &__panel {
        display: none;
        background-color: $color-white;
        border-top-left-radius: $borer-radius;
        border-top-right-radius: $borer-radius;
        &.active {
            display: block;
        }
    }
    &__footer {
        background-color: $color-bg-light;
        //position: sticky;
        position: -webkit-sticky;
        bottom: 0;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
        -webkit-align-items: center;
        -webkit-justify-content: center;
        padding: 15px;
        flex-flow: row wrap;
        -webkit-flex-flow: row-reverse wrap;
        border-bottom-left-radius: $borer-radius;
        border-bottom-right-radius: $borer-radius;
        padding-top: 5px !important;
    }
    &__footer-text {
        opacity: 1;
    }
    &__footer-warning {
        // color: $color-black-solid;
        color: $color-second-dark;
        font-weight: 400;
        font-style: italic;
    }
    &__footer-row {
        text-align: center;
        flex-basis: 40%;
        max-width: none;
        padding: 0;
        text-align: center;
        background-color: white;
        color: $color-black-solid;
        cursor: pointer;
        margin-left: 10px;
        border-radius: 4px;
        height: 40px;
    }
    &__button {
        flex-basis: 40%;
        max-width: none;
        padding: 0;
        text-align: center;
        background-color: $color-button;
        color: $color-black-solid;
        cursor: pointer;
        margin-left: 30px;
        border-radius: 4px;
        height: 40px;
        &:first-of-type {
            margin-left: 10px;
            font-weight: 500 !important;
            font-size: 15px;
            text-transform: uppercase;
        }
        &--inverse {
            color: $color-primary;
            background-color: $color-button-hover;
        }
        &:hover {
            background-color: $color-button-hover;
        }
    }
    &__title {
        margin-top: 0;
        color: $color-white;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
    }
    &__reset, &__end {
        text-transform: uppercase;
        font-weight: 600;
        text-decoration: none;
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: block;
        line-height: 40px;
        font-size: 15px;
        &,&:hover,&:active,&:focus {
            color: #e00000 !important;
        }
    }
}
