.new-trip-btn {
	font-size: 1em;
}
.custom-button {
	margin: 20px !important;
	height: 45px;
	line-height: 45px;
	width: calc(100% - 50px);
	font-size: 1em !important;
}

.customer-btn {
	height: 40px;
	font-size: 1em;
	text-transform: capitalize;
	min-width: 80px;
}

#bookNewTrip {
	font-size: 1em;
}

.nb-form-control {
	margin-bottom: 1rem;
	display: flex;
	display: -webkit-flex;
	flex-flow: row no-wrap;
	//align-items: center;
	position: relative;
	border: none;	
	padding: 0 10px;

	& > label, & > label + div {
		font-size: 1em;
	}

	& label > span {
		font-size: 0.8em;
	}

	&__group {
		display: flex;
	}
	&__group--expand {
		flex: 1;
	}

	&__swap {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-right: 10px;
		margin-right: 10px;
		border-radius: 5px;
		color: white;
		background: #a6ceda;
		&:hover {
			background: $color-button-hover;
			cursor: pointer;
		}
	}
	&__chk-group {
		&-last {
			margin-bottom: 0 !important;
		}
		label {
			position: relative;
			height: 30px;
			line-height: 25px;
			text-indent: 30px;
			font-weight: 600;
			color:$color-white;
			input {
				position: absolute;
				height: 20px;
				width: 20px;
				top: 0;
				left: 0;
			}
		}
	}

	&--right {
		justify-content: flex-end;
	}
	&--date-time {
		flex-flow: row;
		display: flex;
		justify-content: space-between;
		.nb-form-control__input {
			width: 100%;
			@include mobile {
				width: 95% !important;
			}
		}
		.first {
			margin-right: 10px;
		}
		.second {
			//margin-left: 10px;
		}
	}
	&__space {
		width: 10px;
	}
	&__icon {
		margin-right: 5px;
		//display: none;
		&--trip {
			&:before {
				@include icon-container(1.7em);
				@include icon(circle, $color-primary-dark);
				background-size: 75%!important;
			}
		}

		&--minus {
			cursor: pointer;
			font-size: 1.5em;
			&:before {
				@include icon-container(1.3em);
				@include icon(minus, $color-second-dark);
			}
		}

		&--place {
			&:before {
				@include icon-container(1.7em);
				@include icon(place, $color-second-dark);
			}
		}
		&--person:before {
			@include icon-container(1.7em);
			@include icon(person, $color-primary-dark);
		}
		&--lock:before {
			@include icon-container(1.7em);
			@include icon(lock, $color-white);
		}
		&--phone:before {
			@include icon-container(1.7em);
			@include icon(local-phone, $color-primary-dark);
		}
		&--email:before {
			@include icon-container(1.7em);
			@include icon(email, $color-white);
		}
		&--more-place{
			cursor: pointer;
			font-size: 1.5em;
			&:before {
				@include icon-container(1.3em);
				@include icon(plus, $color-second-dark);
			}
		}
		&--additional-place{
			cursor: pointer;
			font-size: 1.5em;
			&:before {
				@include icon-container(1.2em);
				@include icon(stop-place, $color-success);
			}
		}
		&--car:before {
			@include icon-container(1.7em);
			@include icon(car, $color-white);
		}
		&--filter:before {
			@include icon-container(1.7em);
			@include icon(filter, $color-primary-dark);
		}
		&--direction:before {
			@include icon-container(1.7em);
			@include icon(repeat, $color-white);
		}
		&--calendar:before {
			@include icon-container(1.7em);
			@include icon(calendar, $color-primary-dark);
		}

		&--clock {
			margin-left: 10px;
			&:before {
				@include icon-container(1.7em);
				@include icon(clock, $color-primary-dark);
			}
		}

		&--check:before {
			@include icon-container(1.7em);
			@include icon(check, $color-primary-dark);
		}
		&--flight:before {
			@include icon-container(1.7em);
			@include icon(flight, $color-white);
		}
		&--edit:before {
			@include icon-container(1.7em);
			@include icon(pencil, $color-white);
		}
		&--reset {
			opacity: 1;
			cursor: pointer;
			transition: opacity 0.3s ease;
			background: transparent;
			position: absolute;
			display: block !important;
			background: white;
			width: 30px;
			height: 35px;
			right: 11px;
			top: 0;
			border-radius: 5px;
			margin: 0 !important;
			&:hover {
				opacity: 1;
				&:before {
					//@include icon(multiply, $color-button-hover);
				}
			}
			&:before {
				@include icon-container(25px);
				@include icon(multiply, $color-black-solid);
				background-size: 75%!important;
				position: absolute;
				z-index: 1;
				right: 2px!important;
				top: 7px!important;
				left: auto!important;
				background-color: $color-primary-dark;
				border-radius: 50%;
			}
		}

		&--swap {
			display: block !important;
			font-size: 1.5em;
		}

		&--swap:before {
			@include icon-container(1.3em);
			@include icon(swap, $color-second-dark);
		}
		&--landscape:before {
			@include icon-container(1.7em);
			@include icon(landscape, $color-white);
		}
	}

	&__icon--swap {

	}

	&__input {
		padding: 0 5px;
		border: none;
		width: 100%;
		flex: 1;
		-webkit-flex: 1;
		border-bottom: 1px solid $color-grey;
		height: 40px;
		line-height: 40px;
		font-size: 16px;
		border-radius: 5px;
		@include transition(border);
		&:focus {
			outline: none;
			border-bottom-color: $color-primary;
		}	
		&:focus~.nb-form-control__icon--reset {
			opacity: 1;
			pointer-events: all;
		}
		&--textarea {
			height: 4em;
		}
	}
	&__select {
		width: 100%;
		border-bottom: 1px solid $color-grey;
		&:focus {
			outline: none;
			border-bottom-color: $color-primary;
		}
	}
	&__error {
		width: 100%;
		color: $color-error;
		font-size: 12px;
		font-style: italic;
		margin-top: 3px;
	}

	& > label {
		font-size: 1em;
	}
}

.switch {
	&__popup {
		box-shadow: 0 1px 2px 0 rgba(0,0,0,.2);
		border-radius: 3px;
		border: 1px solid #ccc;
		max-width: 320px;
		overflow: hidden;
	}
	&__header {
		display: flex;
		border-bottom: 1px solid $color-grey-light;
	}
	&__header-item {
		padding: 10px;
		width: 50%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		&.active {
			background-color: $color-grey-light;
		}
		&--calendar:before {
			@include icon-container(1.5em);
			@include icon(calendar, $color-primary-dark);
		}
		&--clock:before {
			@include icon-container(1.5em);
			@include icon(clock, $color-primary-dark);
		}
	}
	&__confirm {
		background: $color-primary-dark;
		margin-top: 5px;
		color: $color-white;
		font-weight: 600;
		font-size: 16px;
		.button {
			max-width: none;
			padding: 0; 
		}
		.btn-ripple {
			width: 100%;
			display: flex;
			padding: 10px 0 ;
			align-items: center;
			justify-content: center;
			&:before {
				@include icon-container(1.5em);
			@include icon(check, $color-white);
			}
		}
	}
}
